import React, { useContext } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { SiteLink } from '../SiteLink'
import Icon from '../Icon'
import ProductThumbnail from '../ProductThumbnail'
import FourProdsGrid from '../FourProdsGrid'
import { setQuickShopOpen } from '../../actions'
import useFilterProducts from '../../hooks/useFilterProducts'
import { DictionaryContext } from '../../contexts'
import {
  mobileVW,
  desktopVW,
  headerHeight,
  color,
  desktopBreakpoint,
  zIndex
} from '../../styles'
import { quart, expo } from 'styles/eases'

const CollectionGrid = ({ products }) => {
  const dictionary = useContext(DictionaryContext)
  const dispatch = useDispatch()
  const { categoriesFilter, applyOnsFilter, colorsFilter } = useSelector(
    ({ selections }) => selections
  )

  const smallProds = products.filter(
    (prod, index) => index === 3 || index === 4 || index === 5 || index === 6
  )

  const regularProds = products.filter(
    (prod, index) => index !== 4 && index !== 5 && index !== 6
  )

  const fileteredProducts = useFilterProducts(products)

  const mobileProds =
    categoriesFilter.length === 0 &&
    applyOnsFilter.length === 0 &&
    colorsFilter.length === 0
      ? regularProds
      : fileteredProducts

  return (
    <>
      <MobileView className='hide-on-desktop'>
        {fileteredProducts.length > 0 ? (
          <>
            {mobileProds.map(
              ({ title, slug, productImages, shopifyProduct, preorder }, index) => {
                if (
                  fileteredProducts.length > 7 &&
                  index === 3 &&
                  categoriesFilter.length === 0 &&
                  applyOnsFilter.length === 0 &&
                  colorsFilter.length === 0
                ) {
                  return (
                    <FourProdsGrid
                      key={index}
                      products={smallProds}
                      collection
                    />
                  )
                } else {
                  return (
                    <GridItemLink key={index} slug={slug}>
                      <ProductThumbnail
                        product={{ title, productImages, shopifyProduct, preorder }}
                      />
                    </GridItemLink>
                  )
                }
              }
            )}
          </>
        ) : (
          <NoProductFound>{dictionary.noResults}</NoProductFound>
        )}
      </MobileView>
      <DesktopView className='hide-on-mobile'>
        {fileteredProducts.length > 0 ? (
          <>
            {fileteredProducts.map(
              ({ title, slug, productImages, shopifyProduct, preorder, pairWith, color, shipsBy }, index) => {
                return (
                  <GridItem
                    key={index}
                    className={
                      index === 0 &&
                      products.length >= 6 &&
                      categoriesFilter.length === 0 &&
                      applyOnsFilter.length === 0 &&
                      colorsFilter.length === 0
                        ? 'big-product'
                        : index === 1 &&
                      products.length >= 9 &&
                      categoriesFilter.length === 0 &&
                      applyOnsFilter.length === 0 &&
                      colorsFilter.length === 0
                          ? 'medium-product'
                          : index === 2 &&
                      products.length >= 16 &&
                      categoriesFilter.length === 0 &&
                      applyOnsFilter.length === 0 &&
                      colorsFilter.length === 0
                            ? 'big-product-two'
                            : null
                    }
                  >
                    <SiteLink slug={slug}>
                      <ProductThumbnail
                        product={{ title, productImages, shopifyProduct, preorder }}
                      />
                    </SiteLink>
                    <StyledQuickShop
                      aria-label='quick shop'
                      onClick={() => dispatch(setQuickShopOpen({
                        shopifyProduct,
                        title,
                        preorder,
                        shipsBy,
                        pairWith,
                        color
                      }))}
                    >
                      <Icon name='quick-shop' />
                      {dictionary.quickShop}
                    </StyledQuickShop>
                  </GridItem>
                )
              }
            )}
          </>
        ) : (
          <NoProductFound>{dictionary.noResults}</NoProductFound>
        )}
      </DesktopView>
    </>
  )
}

const MobileView = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${mobileVW(16)};
  grid-row-gap: ${mobileVW(16)};
  margin-top: ${headerHeight.mobile};
  padding: 0 ${mobileVW(16)};
  overflow: hidden;
  margin-top: ${mobileVW(196)};

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const DesktopView = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: ${desktopVW(383)};
    gap: ${desktopVW(45)} ${desktopVW(60)};
    left: ${desktopVW(361)};
    padding: 0 ${desktopVW(60)} 0 0;
    margin: ${desktopVW(180)} ${desktopVW(361)} 0 0;
    overflow: hidden;
  }
`
const GridItemLink = styled(SiteLink)`
  position: relative;
  height: ${mobileVW(384)};
  width: ${mobileVW(343)};
  background: ${color.grey};
  overflow: hidden;
`

const StyledQuickShop = styled.button`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    opacity: 0;
    transition: opacity .6s ${expo.out};
    display: flex;
    align-items: center;
    position: absolute;
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(17)};
    padding: 0 ${desktopVW(4)};
    top: ${desktopVW(32)};
    right: ${desktopVW(29)};
    z-index: ${zIndex.medium};
    color: ${color.yellow};
    background: ${color.black};

    svg {
      fill: ${color.yellow};
      width: ${desktopVW(12)};
      height: ${desktopVW(10)};
      margin-right: ${desktopVW(5)};
    }
  }
`

const GridItem = styled.div`
  position: relative;
  height: ${mobileVW(384)};
  width: ${mobileVW(343)};
  background: ${color.grey};
  overflow: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(383)};
    width: ${desktopVW(300)};

    .product-thumbnail-image-wrapper {
      height: ${desktopVW(242)};
    }

    &.big-product {
      grid-area: 1 / 1 / 3 / 3;
      height: 100%;
      width: 100%;

      .logo-monogram {
        height: ${desktopVW(180)};
        width: ${desktopVW(180)};
        top: ${desktopVW(30)};
        margin-left: ${desktopVW(-90)};
      }

      .product-thumbnail-image-wrapper {
        height: ${desktopVW(432)};
      }
    }

    &.medium-product {
      grid-area: 3 / 1 / 4 / 3;
      height: 100%;
      width: 100%;

      .logo-monogram {
        height: ${desktopVW(100)};
        width: ${desktopVW(100)};
        top: ${desktopVW(20)};
        margin-left: ${desktopVW(-50)};
        /* left: ${desktopVW(220)}; */
      }
    }

    &.big-product-two {
      grid-area: 6 / 1 / 8 / 3;
      height: 100%;
      width: 100%;

      .logo-monogram {
        height: ${desktopVW(180)};
        width: ${desktopVW(180)};
        top: ${desktopVW(30)};
        margin-left: ${desktopVW(-90)};
      }

      .product-thumbnail-image-wrapper {
        height: ${desktopVW(432)};
      }
    }

    p {
      transition: color 0.8s ${quart.inOut};
    }

    &:hover {
      p {
        transition-duration: 1s;
        transition-timing-function: ${expo.out};
        color: ${color.yellow};
      }

      ${StyledQuickShop} {
        opacity: 1;
      }
    }
  }
`

const NoProductFound = styled.div`
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${mobileVW(120)};
  font-size: ${mobileVW(13)};
  line-height: ${mobileVW(24)};

  @media (min-width: ${desktopBreakpoint}) {
    height: auto;
    width: 100%;
    font-size: ${desktopVW(30)};
    line-height: ${desktopVW(30)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-67%, -50%);
  }
`

export default CollectionGrid
