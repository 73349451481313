import React, { useContext } from 'react'
import styled from 'styled-components'
import FreeShipping from '../FreeShipping'
import { DictionaryContext } from '../../contexts'
import {
  mobileVW,
  desktopVW,
  headerHeight,
  zIndex,
  desktopBreakpoint
} from '../../styles'

const CollectionMenu = ({ title, prodsNumber }) => {
  const dictionary = useContext(DictionaryContext)

  return (
    <StyledCollectionMenu id='collection-page-header'>
      <Title>{title}</Title>
      {prodsNumber > 1 && (
        <ProdsNumber>
          ({prodsNumber} {dictionary.products} )
        </ProdsNumber>
      )}
      <FreeShipping animated hideDesktop />
    </StyledCollectionMenu>
  )
}

const StyledCollectionMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: ${headerHeight.mobile};
  width: 100%;
  overflow: hidden;

  .free-shipping-strip {
    position: fixed;
    width: 100%;
    top: ${headerHeight.mobile};
    z-index: ${zIndex.medium + 1};
  }

  @media (min-width: ${desktopBreakpoint}) {
    top: ${headerHeight.desktop};
  }
`

const Title = styled.h1`
  font-size: ${mobileVW(42)};
  line-height: ${mobileVW(35)};
  padding: ${mobileVW(55)} ${mobileVW(50)} 0 ${mobileVW(50)};
  text-align: center;
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(80)};
    line-height: ${desktopVW(68)};
    padding: ${desktopVW(60)} ${desktopVW(450)} 0 ${desktopVW(450)};
  }
`

const ProdsNumber = styled.p`
  font-size: ${mobileVW(10)};
  line-height: ${mobileVW(8)};
  padding: ${mobileVW(20)} ${mobileVW(40)};
  text-align: center;
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(10)};
    line-height: ${desktopVW(8)};
    padding: ${desktopVW(30)} ${desktopVW(50)} 0;
  }
`

export default CollectionMenu
