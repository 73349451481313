import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import CollectionMenu from '../components/collection/CollectionMenu'
import CollectionGrid from '../components/collection/CollectionGrid'
import Media from '../components/Media'
import FilterBox from '../components/collection/FilterBox'
import useSticky from 'hooks/window/useSticky'
import styled from 'styled-components'

const CollectionGridWrapper = styled.div`
  position: relative;
`

const Collection = ({
  data: {
    contentfulCollectionPage: {
      title,
      slug,
      seoTitle,
      seoDescription,
      products,
      media
    }
  }
}) => {
  const [filterBoxRef, ref] = useSticky()
  return (
    <>
      <SEO slug={slug} title={seoTitle} description={seoDescription} isPage />
      <CollectionMenu title={title} prodsNumber={products.length} />
      <CollectionGridWrapper ref={ref}>
        <CollectionGrid products={products} />
        <FilterBox ref={filterBoxRef} seoDescription={seoDescription} />
      </CollectionGridWrapper>
      <Media media={media} marginTop />
    </>
  )
}

export const PageQuery = graphql`
  query Collection($node_locale: String!, $id: String!) {
    contentfulCollectionPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      slug
      seoTitle
      seoDescription
      ...collectionPage
    }
  }
`

export default Collection
