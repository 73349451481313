import detectIt from 'detect-it'
import SmoothScroll from 'components/animation/SmoothScroll'
import useRaf from './useRaf'
import { useRef, useEffect } from 'react'
import useResizeObserver from './useResizeObserver'
import offsetTop from 'utils/offsetTop'
import { desktop } from 'styles/index'
import Window from 'utils/Window'

const useSticky = (progressive = false, bottom = false) => {
  const localRef = useRef()
  const height = useRef(0)
  const top = useRef(0)
  const wh = useRef(0)
  const isTouch = useRef(detectIt.primaryInput === 'touch')
  const isDesktop = useRef()

  const localParentRef = useRef()
  const parentHeight = useRef(0)
  const parentTop = useRef(0)

  useResizeObserver(localParentRef, (entry) => {
    if (!isTouch.current) {
      parentTop.current = offsetTop(localParentRef.current)
      height.current = localRef.current.offsetHeight
      parentHeight.current = entry.height
      wh.current = Window.innerHeight
      top.current = offsetTop(localRef.current)
    }

    isDesktop.current = Window.innerWidth >= desktop
  })

  if (!isTouch.current) {
    useRaf(() => {
      const deltaTop = top.current - parentTop.current
      const deltaY = parentHeight.current - height.current
      let y = 0

      if (progressive) {
        const d = height.current - wh.current
        if (d > 0) {
          const p = Math.min((-SmoothScroll.y - parentTop.current) / (parentTop.current - parentHeight.current + wh.current), 1)
          y = p * deltaY
        } else {
          y = SmoothScroll.y
        }
      } else {
        if (bottom) {
          y = Math.max(0, SmoothScroll.y)
        } else {
          y = Math.max(0, SmoothScroll.y - parentTop.current - deltaTop)
        }
      }

      y = Math.min(y, deltaY - deltaTop)
      localRef.current.style.transform = `translate3d(0,${y}px,0)`
    })
  } else {
    useEffect(() => {
      localRef.current.style.position = isDesktop.current ? 'sticky' : ''
    }, [isDesktop.current])
  }

  return [localRef, localParentRef]
}

export default useSticky
